<template>
  <div
    :class="dropdownType === DropdownTypes.cardDropdown ? 'cardDropdown' : 'formDropdown'"
    @focusin="if (dropdownType === DropdownTypes.cardDropdown) dropdownOpened = true;"
    @focusout="
      dropdownOpened = false;
      filterText = '';
    "
  >
    <input
      v-model="filterText"
      :placeholder="selectedOption?.label"
      :readonly="dropdownType === DropdownTypes.formDropdown"
      :class="dropdownType !== DropdownTypes.cardDropdown ? 'select' : 'dropdownInput'"
      @click="if (dropdownType !== DropdownTypes.cardDropdown) dropdownOpened = !dropdownOpened;"
    />
    <svg
      :class="[dropdownOpened ? 'dropdownCollapsed' : 'dropdownExpanded']"
      @click="dropdownOpened = !dropdownOpened"
    ></svg>
    <div class="dropdownContainer" v-if="dropdownOpened">
      <ul class="dropdownList">
        <li
          v-for="option in filteredOptions"
          :key="option.value"
          @mousedown="selectOption(option)"
          @keyup.enter="selectOption(option)"
        >
          {{ option.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, ref, watch } from "vue";

import { DropdownOption } from "../../../types/general";

/* eslint-disable no-unused-vars */
export enum DropdownTypes {
  cardDropdown = 0,
  formDropdown = 1,
  filterFormDropdown = 2,
}
/* eslint-enable no-unused-vars */

export default defineComponent({
  name: "Dropdown",
  props: {
    options: { required: true, type: Array as PropType<DropdownOption[]> },
    parentSelectedOption: { required: false, type: Object as PropType<DropdownOption> },
    dropdownType: { required: false, type: Number as PropType<DropdownTypes>, default: DropdownTypes.cardDropdown },
  },
  emits: ["optionSelected"],
  setup(props, { emit }) {
    let filterText = ref("");
    let selectedOption = ref(props.parentSelectedOption as DropdownOption);

    const filteredOptions = computed(() => {
      if (!filterText.value) {
        return props.options;
      }
      const filter = filterText.value.toLowerCase();
      return props.options.filter((option) => option.label.toLowerCase().includes(filter));
    });
    let dropdownOpened = ref(false);

    function selectOption(option: DropdownOption) {
      selectedOption.value = option;
      dropdownOpened.value = false;
      emit("optionSelected", option);
    }
    dropdownOpened.value = false;
    watch(
      () => props.parentSelectedOption,
      (newval) => {
        selectedOption.value = newval as DropdownOption;
      }
    );

    return { dropdownOpened, filterText, filteredOptions, selectOption, selectedOption, DropdownTypes };
  },
});
</script>

<style scoped lang="scss" src="./dropdown.scss"></style>
