import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled", "placeholder"]
const _hoisted_2 = ["disabled", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.textarea)
    ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
        key: 0,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
        disabled: _ctx.readonly,
        placeholder: _ctx.translatedPlaceholder,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('newValue', ($event.target as HTMLInputElement).value))),
        onFocusout: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('format', ($event.target as HTMLInputElement).value)))
      }, null, 40, _hoisted_1)), [
        [_vModelText, _ctx.localValue]
      ])
    : _withDirectives((_openBlock(), _createElementBlock("input", {
        key: 1,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localValue) = $event)),
        disabled: _ctx.readonly,
        placeholder: _ctx.translatedPlaceholder,
        onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('newValue', ($event.target as HTMLInputElement).value))),
        onFocusout: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('format', ($event.target as HTMLInputElement).value)))
      }, null, 40, _hoisted_2)), [
        [_vModelText, _ctx.localValue]
      ])
}