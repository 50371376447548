import { vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    disabled: _ctx.readonly,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event)),
    type: "checkbox",
    placeholder: "temp",
    onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCheck(($event.target as HTMLInputElement).checked)))
  }, null, 40, _hoisted_1)), [
    [_vModelCheckbox, _ctx.checked]
  ])
}