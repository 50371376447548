import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "buttonWrapper" }
const _hoisted_2 = { class: "subButtonsContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavMenuButton = _resolveComponent("NavMenuButton", true)!

  return (_openBlock(), _createElementBlock("main", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: _normalizeClass([_ctx.selected ? 'buttonSelected' : 'button'])
      }, [
        _createElementVNode("div", {
          class: "buttonContainer",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigate && _ctx.navigate(...args)))
        }, [
          (_ctx.buttonInfo.icon)
            ? (_openBlock(), _createElementBlock("svg", {
                key: 0,
                preserveAspectRatio: "xMaxYMax meet",
                class: _normalizeClass([_ctx.selected ? 'iconSelected' : 'buttonIcon']),
                style: _normalizeStyle({
              maskImage: 'url(' + require('../../../../assets/icons/' + _ctx.buttonInfo.icon + '.svg') + ')',
            })
              }, null, 6))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.selected ? 'textSelected' : 'buttonText'])
          }, _toDisplayString(_ctx.$t(_ctx.buttonInfo.title as string)), 3)
        ]),
        _createElementVNode("div", {
          class: "dropdownContainer",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.expanded = !_ctx.expanded))
        }, [
          (_ctx.buttonInfo.subButtons)
            ? (_openBlock(), _createElementBlock("svg", {
                key: 0,
                preserveAspectRatio: "xMaxYMax meet",
                class: _normalizeClass([_ctx.expanded ? 'collapse' : 'expand'])
              }, null, 2))
            : _createCommentVNode("", true)
        ])
      ], 2)
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttonInfo.subButtons, (subButton) => {
        return (_openBlock(), _createElementBlock("div", {
          key: JSON.stringify(subButton)
        }, [
          _createVNode(_component_NavMenuButton, { buttonInfo: subButton }, null, 8, ["buttonInfo"])
        ]))
      }), 128))
    ], 512), [
      [_vShow, _ctx.expanded]
    ])
  ]))
}