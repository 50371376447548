<template>
  <main>
    <div class="buttonWrapper">
      <button :class="[selected ? 'buttonSelected' : 'button']">
        <div class="buttonContainer" @click="navigate">
          <svg
            v-if="buttonInfo.icon"
            preserveAspectRatio="xMaxYMax meet"
            :class="[selected ? 'iconSelected' : 'buttonIcon']"
            :style="{
              maskImage: 'url(' + require('../../../../assets/icons/' + buttonInfo.icon + '.svg') + ')',
            }"
          ></svg>
          <div :class="[selected ? 'textSelected' : 'buttonText']">{{ $t(buttonInfo.title as string) }}</div>
        </div>
        <div class="dropdownContainer" @click="expanded = !expanded">
          <svg
            v-if="buttonInfo.subButtons"
            preserveAspectRatio="xMaxYMax meet"
            :class="[expanded ? 'collapse' : 'expand']"
          ></svg>
        </div>
      </button>
    </div>
    <div class="subButtonsContainer" v-show="expanded">
      <div v-for="subButton in buttonInfo.subButtons" :key="JSON.stringify(subButton)">
        <NavMenuButton :buttonInfo="subButton" />
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { useGeneralStore } from "@/store/general";
import { PropType, computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router";

import router from "../../../../router";
import { NavMenuButtonInfo } from "../../../../types/general";

export default defineComponent({
  name: "NavMenuButton",
  props: {
    buttonInfo: {
      required: true,
      type: Object as PropType<NavMenuButtonInfo>,
    },
  },
  setup(props) {
    let expanded = ref(true);
    const currentRoute = useRoute();
    const selected = computed(
      () => (props.buttonInfo.path && currentRoute.fullPath === props.buttonInfo.path) ?? false
    );

    function navigate() {
      if (props.buttonInfo.path) {
        // const generalStore = useGeneralStore();
        // generalStore.lastPath = []; TODO manage "going back" for cases where you change branch in navtree
        router.push({ path: props.buttonInfo.path });
      }
      expanded.value = true;
    }

    return { expanded, navigate, selected };
  },
});
</script>

<style scoped lang="scss" src="./NavMenuButton.scss"></style>
