import { openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "mainContainer" }
const _hoisted_3 = { class: "topButtonContainer" }
const _hoisted_4 = { class: "buttonContainer" }
const _hoisted_5 = { class: "drawer" }
const _hoisted_6 = { class: "drawerTextContainer" }
const _hoisted_7 = { class: "nameText" }
const _hoisted_8 = { class: "emailText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavMenuButton = _resolveComponent("NavMenuButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Popup = _resolveComponent("Popup")!
  const _component_ModalWindow = _resolveComponent("ModalWindow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("svg", {
        preserveAspectRatio: "xMaxYMax meet",
        class: "SSLogoIcon"
      }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topButtons, (button) => {
          return (_openBlock(), _createElementBlock("div", {
            key: JSON.stringify(button)
          }, [
            (_ctx.permission && _ctx.permission >= (button.minPermission as Permission))
              ? (_openBlock(), _createBlock(_component_NavMenuButton, {
                  key: 0,
                  buttonInfo: button
                }, null, 8, ["buttonInfo"]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bottomButtons, (button) => {
          return (_openBlock(), _createElementBlock("div", {
            key: JSON.stringify(button)
          }, [
            (_ctx.permission && _ctx.permission >= (button.minPermission as Permission))
              ? (_openBlock(), _createBlock(_component_NavMenuButton, {
                  key: 0,
                  buttonInfo: button
                }, null, 8, ["buttonInfo"]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_router_link, {
        class: "drawerLink",
        to: "/error"
      }, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createElementVNode("svg", {
            preserveAspectRatio: "xMaxYMax meet",
            class: "userIcon"
          }, null, -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.fullname), 1),
            _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.email), 1)
          ])
        ], undefined, true),
        _: 1
      }),
      _createElementVNode("button", {
        class: "logoutButton",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loggingOut = true))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("svg", {
          preserveAspectRatio: "xMaxYMax meet",
          class: "logoutIcon"
        }, null, -1)
      ]))
    ]),
    (_ctx.loggingOut)
      ? (_openBlock(), _createBlock(_component_ModalWindow, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_Popup, {
              text: "logoutConfirmation",
              color: _ctx.PopupColors.yellow,
              buttons: _ctx.PopupButtons.confirmCancel,
              icon: _ctx.PopupIcons.Warning,
              onConfirm: _ctx.logout,
              onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loggingOut = false))
            }, null, 8, ["color", "buttons", "icon", "onConfirm"])
          ], undefined, true),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}