import { useRobotStore } from "@/store/robot";
import routes from "vue-auto-routing";
import { RouteRecordRaw, createRouter, createWebHashHistory } from "vue-router";

import { Permission, useAuthStore } from "../store/auth";
import { useClientStore } from "../store/client";
import { useGeneralStore } from "../store/general";

routes.forEach((route) => {
  route.path = route.path.toLowerCase();
  if (route.children) {
    route.children.forEach((childRoute: RouteRecordRaw) => {
      if (route.path === "/robots/schedule") {
        childRoute.path = "/robots/schedule/" + childRoute.path.toLowerCase();
        routes.push(childRoute);
      } else {
        childRoute.path = childRoute.path.toLowerCase();
      }
    });
    if (route.path === "/robots/schedule") {
      delete route.children;
    }
  }
});

routes.find((route) => route.path === "/robots/robot").path = "/robots/:id-:name";

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.addRoute({
  path: "/",
  redirect: () => (useAuthStore().isAuthenticated ? "home" : "login"),
});

router.addRoute({
  path: "/:catchAll(.*)",
  meta: { removeNavigation: true },
  redirect: () => "error",
});

const clientsRedirectExceptions = ["/home", "/login", "/users", "/clients", "/error"];

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const generalStore = useGeneralStore();
  const clientStore = useClientStore();
  const robotStore = useRobotStore();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (generalStore.error !== "" && !(to.fullPath === "/error")) {
      generalStore.error = "";
    }
    if (authStore.isAuthenticated) {
      if (authStore.permission && authStore.permission < (to.meta.minPermission as Permission)) {
        next("/error");
        return;
      } else if (
        authStore.permission &&
        authStore.permission > Permission.SuperUser &&
        !clientStore.currentClient &&
        !clientsRedirectExceptions.includes(to.path)
      ) {
        clientStore.clientSelectionRedirect = true;
        next("/clients");
        return;
      } else if (robotStore.currentRobot === null && to.path.includes("/robots/") && to.path !== "/robots") {
        robotStore.robotSelectionRedirect = true;
        next("/robots");
        return;
      }
      console.log(generalStore.lastPath);
      console.log(!generalStore.goingBack);
      console.log(from.meta.requiresAuth);
      console.log(!(Object.keys(to.params).length > 0));
      console.log(to);

      if (!generalStore.goingBack && from.meta.requiresAuth) {
        // && !(Object.keys(to.params).length > 0)) {
        generalStore.lastPath.push(from.fullPath);
        console.log(generalStore.lastPath);
      }
      generalStore.goingBack = false;
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
