import moment from "moment-timezone";

interface TimezoneOption {
  label: string;
  value: string;
}

const cities = [
  "America/Toronto", // Canada (Eastern)
  "America/Vancouver", // Canada (Pacific)
  "America/Edmonton", // Canada (Mountain)
  "America/Winnipeg", // Canada (Central)
  "America/Halifax", // Canada (Atlantic)
  "America/St_Johns", // Canada (Newfoundland)
  "America/New_York", // USA (Eastern)
  "America/Chicago", // USA (Central)
  "America/Denver", // USA (Mountain)
  "America/Los_Angeles", // USA (Pacific)
  "America/Anchorage", // USA (Alaska)
];

export const timezones: TimezoneOption[] = cities
  .filter((city) => city.startsWith("America/"))
  .map((city) => {
    const offset = moment.tz(city).utcOffset() / 60; // Get UTC offset in hours
    const utcLabel = `UTC${offset >= 0 ? `+${offset}` : offset}`;
    return {
      label: `${utcLabel} - ${city.replace("America/", "").replace(/_/g, " ")}`,
      value: city,
      offset,
    };
  })
  .sort((a, b) => a.offset - b.offset) // Sort by UTC offset
  .map(({ label, value }) => ({ label, value }));
