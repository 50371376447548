import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withKeys as _withKeys, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["placeholder", "readonly"]
const _hoisted_2 = {
  key: 0,
  class: "dropdownContainer"
}
const _hoisted_3 = { class: "dropdownList" }
const _hoisted_4 = ["onMousedown", "onKeyup"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.dropdownType === _ctx.DropdownTypes.cardDropdown ? 'cardDropdown' : 'formDropdown'),
    onFocusin: _cache[3] || (_cache[3] = ($event: any) => {if (_ctx.dropdownType === _ctx.DropdownTypes.cardDropdown) _ctx.dropdownOpened = true;}),
    onFocusout: _cache[4] || (_cache[4] = ($event: any) => {
      _ctx.dropdownOpened = false;
      _ctx.filterText = '';
    })
  }, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterText) = $event)),
      placeholder: _ctx.selectedOption?.label,
      readonly: _ctx.dropdownType === _ctx.DropdownTypes.formDropdown,
      class: _normalizeClass(_ctx.dropdownType !== _ctx.DropdownTypes.cardDropdown ? 'select' : 'dropdownInput'),
      onClick: _cache[1] || (_cache[1] = ($event: any) => {if (_ctx.dropdownType !== _ctx.DropdownTypes.cardDropdown) _ctx.dropdownOpened = !_ctx.dropdownOpened;})
    }, null, 10, _hoisted_1), [
      [_vModelText, _ctx.filterText]
    ]),
    (_openBlock(), _createElementBlock("svg", {
      class: _normalizeClass([_ctx.dropdownOpened ? 'dropdownCollapsed' : 'dropdownExpanded']),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dropdownOpened = !_ctx.dropdownOpened))
    }, null, 2)),
    (_ctx.dropdownOpened)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, (option) => {
              return (_openBlock(), _createElementBlock("li", {
                key: option.value,
                onMousedown: ($event: any) => (_ctx.selectOption(option)),
                onKeyup: _withKeys(($event: any) => (_ctx.selectOption(option)), ["enter"])
              }, _toDisplayString(option.label), 41, _hoisted_4))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 34))
}